<!-- login.component.html -->
<div class="container mt-5">
  <div class="row justify-content-center">
    <div class="col-md-6">
      <div class="card">
        <div class="card-header bg-primary text-white">
          <h4 class="mb-0">Multistore Login</h4>
        </div>
        <div class="card-body">
          <form>
            <div class="form-group">
              <label for="username">Email:</label>
              <input type="email" class="form-control" id="usernameMuli" name="usernameMuli" [(ngModel)]="loginParams.username" required>
            </div>
            <div class="form-group">
              <label for="password">Password:</label>
              <input type="password" class="form-control" id="passwordMulti" name="passwordMulti" [(ngModel)]="loginParams.password" required>
            </div>
            <button type="button" class="btn btn-primary" (click)="onLoginSubmit()">Login</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
