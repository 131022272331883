import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalParameters {
  customNumber: number = 0;
  customString: string = '';

  constructor() { }
}
