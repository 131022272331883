<!-- mat-cart.component.html -->

<div class="container mt-3 fade-in">
  <form class="row g-3 mb-3 align-items-center">
    <div class="col-auto">
      <label for="client_nameN" class="form-label">Client</label>
      <input
        type="text"
        id="client_nameN"
        class="form-control" readonly
        #clientIdInput
        (click)="openClientPopup()"
        (keydown.Space)="openClientPopup()"
      />
    </div>
    <div class="col-auto">
      <label for="fromDate" class="form-label">From Date:</label>
      <div class="input-group">
        <input
          id="fromDate"
          name="fromDate"
          class="form-control"
          placeholder="yyyy-mm-dd"
          [value]="fromDate ? formatter.format(fromDate) : ''"
          ngbDatepicker
          #dp="ngbDatepicker"
          (dateSelect)="onFromDateSelect($event)"
          readonly
        />
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" (click)="dp.toggle()" type="button">
            <i class="bi bi-calendar"></i>
          </button>
        </div>
      </div>
    </div>
  
    <div class="col-auto">
      <label for="toDate" class="form-label">To Date:</label>
      <div class="input-group">
        <input
          id="toDate"
          name="toDate"
          class="form-control"
          placeholder="yyyy-mm-dd"
          [value]="toDate ? formatter.format(toDate) : ''"
          ngbDatepicker
          #dpTo="ngbDatepicker"
          (dateSelect)="onToDateSelect($event)"
          readonly
        />
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" (click)="dpTo.toggle()" type="button">
            <i class="bi bi-calendar"></i>
          </button>
        </div>
        <div class="col-auto d-flex ml-2 ms-2">
          <button  (click)="search()" [disabled]="isLoading" [class.loading]="isLoading">
            <span *ngIf="!isLoading">Search</span>
            <span class="spinnerplace" *ngIf="isLoading">
              <i class="spinner"></i> Loading...
            </span>
          </button>
        </div>
      </div>
    </div>
  
    
  </form>

  <div class="table-responsive shadow-sm rounded">
  <table class="table table-striped table-hover table-bordered">
    <thead class="table-dark text-center">
      <tr>
        <th scope="col">Client Name</th>
        <th scope="col">Date</th>
        <th scope="col">Total Debit</th>
        <th scope="col">Total Credit</th>
        <th scope="col">Saldo</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of matCartList" class="align-middle">
        <td>{{ item.client_name }}</td>
        <td>{{ item.date | date:'dd.MM.yyyy' }}</td>
        <td class="text-end">{{ item.total_debit | currency:'USD' }}</td>
        <td class="text-end">{{ item.total_credit | currency:'USD' }}</td>
        <td class="text-end">{{ item.saldo | currency:'USD' }}</td>
      </tr>
    </tbody>
  </table>
</div>

</div>
