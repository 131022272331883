<div class="popup-container">
<div class="modal-header">
  <h4 class="modal-title">Confirmation</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="cancel()">
    <!-- <i class="bi bi-x"></i> -->
  </button>
</div>
<div class="modal-body">
  <p>Are you sure you want to delete?</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="cancel()">Cancel</button>
  <button type="button" class="btn btn-danger" (click)="confirm()">Delete</button>
</div>
</div>