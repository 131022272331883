<div class="container mt-3 fade-in">
  <form class="row g-3 mb-3 align-items-center">
    <div class="col-auto d-flex align-items-center">
      <label for="client_nameN" class="form-label me-2 mb-0">Client Id:</label>
      <input
        type="text"
        id="client_nameN"
        class="form-control" readonly
        #clientIdInput
        (click)="openClientPopup()"
        (keydown.Space)="openClientPopup()"
      />
    </div>
    <div class="col-auto d-flex align-items-center">
      <label for="fromDate" class="form-label me-2 mb-0">From Date:</label>
      <div class="input-group">
        <input
          id="fromDate"
          name="fromDate"
          class="form-control"
          placeholder="yyyy-mm-dd"
          [value]="fromDate ? formatter.format(fromDate) : ''"
          ngbDatepicker
          #dp="ngbDatepicker"
          (dateSelect)="onFromDateSelect($event)"
          readonly
        />
        <button class="btn btn-outline-secondary" (click)="dp.toggle()" type="button">
          <i class="bi bi-calendar"></i>
        </button>
      </div>
    </div>

    <div class="col-auto d-flex align-items-center">
      <label for="toDate" class="form-label me-2 mb-0">To Date:</label>
      <div class="input-group">
        <input
          id="toDate"
          name="toDate"
          class="form-control"
          placeholder="yyyy-mm-dd"
          [value]="toDate ? formatter.format(toDate) : ''"
          ngbDatepicker
          #dpTo="ngbDatepicker"
          (dateSelect)="onToDateSelect($event)"
          readonly
        />
        <button class="btn btn-outline-secondary" (click)="dpTo.toggle()" type="button">
          <i class="bi bi-calendar"></i>
        </button>
      </div>
    </div>

    <input type="hidden" id="language" value="AL" class="form-control" #languageInput />

    <div class="col-auto d-flex align-items-center">
      <button (click)="search()" [disabled]="isLoading" [class.loading]="isLoading">
        <span *ngIf="!isLoading">Search</span>
        <span class="spinnerplace" *ngIf="isLoading">
          <i class="spinner"></i> Loading...
        </span>
      </button>
    </div>

    <div class="col-auto d-flex align-items-center">
      <button (click)="add(-1,access)">
        <i class="bi bi-plus"></i>Add
      </button>
    </div>
  </form>

  <div class="table-responsive shadow-sm rounded">
    <table class="table table-striped table-hover table-bordered">
      <thead class="table-dark text-center">
        <tr>
          <th scope="col">Id</th>
          <th scope="col">Client Name</th>
          <th scope="col">Date</th>
          <th scope="col">Description</th>
          <th scope="col">Recorder</th>
          <th scope="col">Total</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of matOutDocsHead">
          <td>{{ item.fullId }}</td>
          <td>{{ item.client_name }}</td>
          <td>{{ item.data }}</td>
          <td>{{ item.nr_dokumentit }}</td>
          <td>{{ item.staff_name }}</td>
          <td class="text-end">{{ item.total }}</td>
          <td>
            <button (click)="add(item.id,access)">Open</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
