import { Routes } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { MatCartComponent } from './mat-cart/mat-cart.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './services/auth.guard';
import { AuthService } from './services/auth.service';
import { OutDocsComponent } from './documents/outdocs.component';
import { OutdocsOpenComponent } from './documents/outdocs-open.component';
import { ItemsPopupComponent } from './items/items-popup.component';
import { ProductListComponent } from './order/product-list/product-list.component';
import { CartComponent } from './order/cart/cart.component';
import { MultistoreLoginComponent } from './multistore-login/multistore-login.component';
import { MultiStoreSelectorComponent } from './multistore-selector/multistore-selector.component';

export const routes: Routes = [
    {path: 'login', component:LoginComponent},
    {path: 'multistore-login', component:MultistoreLoginComponent},
    {path: 'multistore-selector', component:MultiStoreSelectorComponent},
    {path: 'mat-cart', component: MatCartComponent, canActivate: [AuthGuard] },
    {path: 'client-reservations', component:OutDocsComponent, canActivate: [AuthGuard],data: { doctype: 'OUTR' }},
    {path: 'outdocs', component:OutDocsComponent, canActivate: [AuthGuard],data: { doctype: '' }},
    {path: 'outdocs-open', component:OutdocsOpenComponent, canActivate: [AuthGuard]},
    {path: 'items-popup', component:ItemsPopupComponent, canActivate: [AuthGuard]},
    {path: 'order', component:ProductListComponent, canActivate: [AuthGuard]},
    {path: 'cart', component:CartComponent, canActivate: [AuthGuard]},
    // Add other routes if needed
];
