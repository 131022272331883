<div class="container mt-5">
  <h2 class="mb-4 text-center">Your Cart</h2>
<div class="row" *ngIf="cart.length > 0">
  <div class="col-auto">
    <label for="client_nameN" class="form-label">Client</label>
    <input
      type="text"
      id="client_nameN"
      class="form-control" readonly
      #clientIdInput
      (click)="openClientPopup()"
      (keydown.Space)="openClientPopup()"
    />
  </div>
</div>
  <!-- Empty Cart Message -->
  <div *ngIf="cart.length === 0" class="alert alert-warning text-center mt-2">
    Your cart is empty.
  </div>

  <!-- Cart Items -->
  <div class="row mt-2" *ngIf="cart.length > 0">
    <div class="col-md-12 mb-4" *ngFor="let product of cart">
      <div class="card shadow-sm">
        <div class="card-body d-flex justify-content-between align-items-center">
          <div>
            <h5 class="card-title">{{ product.item_name }}</h5>
            <p class="card-text text-muted">{{ product.quantity }} x {{ product.item_sell_price | currency }}</p>
          </div>
          <button class="btn btn-outline-danger btn-sm" (click)="removeFromCart(product)">
            <i class="bi bi-trash"></i> Remove
          </button>
        </div>
      </div>
    </div>
    
    <!-- Total and Checkout -->
    <div class="col-md-12 text-right mb-5">
      <h2 class="mb-4">Total: <span class="text-success">{{ getTotal() | currency }}</span></h2>
      <button class="btn btn-success btn-lg" (click)="submitTheOrder()" [disabled]="isLoading" [class.loading]="isLoading">
        <span *ngIf="!isLoading"><i class="bi bi-check-circle"></i> Proceed to Checkout</span>
        <span class="spinnerplace" *ngIf="isLoading">
          <i class="spinner"></i> Loading...
        </span>
      </button>
    </div>
  </div>
</div>
