// login.component.ts
import { Injectable ,Component, OnInit, Input,ViewChild, ElementRef } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { MatCart1 } from './login.model'; // Import the C# model
import { tmpLoginParams } from './tmpLoginParams.model';
import { MatStores } from './mat-stores.model';
import { API_ENDPOINTS } from '../api.config';
import { Observable, throwError  } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { getCurrentTimezoneOffsetString } from '../valon.config';
import { AuthService } from '../services/auth.service';
import { RouterModule,Router } from '@angular/router';
import { AccessService } from '../services/access.service';
import { LanguageService } from '../services/language.service';
import { HardwareService } from '../hardware.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  standalone: true,
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  imports: [CommonModule,FormsModule,NgbModule,RouterModule]
})


export class LoginComponent implements OnInit {
  api_call:string = '';
  account_token:string='';
  account_identity:string='';
  loginParams: tmpLoginParams = {
    username: '',
    password: ''
  };
  matStores: MatStores[] = [];
  hardwareId: string;
  isLoading = false;
  constructor(private route: ActivatedRoute,private http: HttpClient,private authService: AuthService, private accessSerive: AccessService,private languageService: LanguageService,private router: Router,private hardwareService: HardwareService) {
    this.hardwareId = this.hardwareService.getHardwareId();
   }

  ngOnInit(): void {

    this.route.queryParamMap.subscribe(params => {
      // Get the value of the 'id' parameter
      let api:string =params.get('api_call') || '';
      let acc:string =params.get('account_token') || '';
      let idt:string =params.get('account_identity') || '';
      this.api_call =decodeURIComponent(api);
      this.account_token =decodeURIComponent(acc);
      this.account_identity = decodeURIComponent(idt);


      this.authService.setAccountAPI(this.api_call);
    });

    if(this.authService.isAuthenticated())
    {
      this.router.navigate(['/']);
    }
  } 

  onLoginSubmit(): void {

    const newOffset = getCurrentTimezoneOffsetString();

    // Validate Username
    if (this.loginParams.username === null || this.loginParams.username === undefined || this.loginParams.username === '') {
      console.error('Username is null or undefined.');
      alert('Username is null or undefined.');
      return;
    }
    // Validate Password
    if (this.loginParams.password === null || this.loginParams.password === undefined || this.loginParams.password === '') {
      console.error('Password is null or undefined.');
      alert('Password is null or undefined.');
      return;
    }
    this.isLoading=true;
    const user_language ='EN';
  
    const body = {
      username:this.loginParams.username,
      password:this.loginParams.password,
      //connection_token:API_ENDPOINTS.connection_token,
      //account_token : API_ENDPOINTS.account_token,
      account_token : this.account_token,
      //connection_identity : API_ENDPOINTS.connection_identity,
      connection_identity : this.account_identity,
      device:'web',
      user_language:user_language,
      hardware_id : this.hardwareId,
      timezone_offset: newOffset
    };
    this.http.post<any>(this.authService.getAccountAPI() +  API_ENDPOINTS.vvclient_method + "/Authorize", body)
    .pipe(
      catchError(this.handleError)
    )
    .subscribe(
      data => {
        if(data.status === false)
        {
          this.isLoading=false;
          alert('Wrong username or password!');
        }
        else
        {
          this.languageService.setLanguage(user_language);
          this.authService.setAuthToken(data.token);
          this.accessSerive.setAccess(data.result.job_positions_access);
          
          
          this.authService.setAccountToken(this.account_token);
          this.authService.setAccountIdentity(this.account_identity);

          //console.log('Data=' + JSON.stringify(data));
          //console.log('Token=' + this.authService.getAuthToken());
          //console.log('Access=' + this.accessSerive.getAccessAll());
          //console.log('OUT21 FORM_ID=' + JSON.stringify(this.accessSerive.filterDataByFormId('s242')) );
          //console.log('OUT21 DOC_TYPE=' + JSON.stringify(this.accessSerive.filterDataByDoctype('OUT21')));
          this.isLoading=false;
          this.router.navigate(['/mat-cart']);
          
        }
      },
      error => {
        //console.error('Error fetching MatCartList:', error);
        this.isLoading=false;
        //alert(this.authService.getAccountAPI());
        window.open(this.authService.getAccountAPI(), '_blank');
        alert(error);
      }
    );
  }
  private handleError(error: HttpErrorResponse): Observable<any> {
    if (error.status === 401) {
      // Handle 401 Unauthorized error (e.g., redirect to login page)
      console.error('Unauthorized request. Redirecting to login page.');
      alert('Unauthorized request. Redirecting to login page.');
      // You can perform additional actions here, such as redirecting to a login page.
    } else {
      alert(error.error);
     
        
      
      // Handle other errors
      console.error('Error:', error.error);
    }

    // Return an observable with a user-facing error message
    return throwError('Something went wrong. Please try again later.');
  }

}
