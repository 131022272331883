<!--<button type="button" class="btn btn-success" (click)="goBack()">Back</button>-->
<div class="container mt-12 fade-in">
    <div class="row">
      <div class="col-md-12">
        
        <div class="card">
          <div class="card-header bg-primary text-white">
            <h3 class="mb-0">{{ headItem.doctype_name}}</h3>
          </div>
          <div class="card-body" >
            <div class="form-group row">
            <div class="col-sm-2">
            <label for="fullId">Full ID:</label>
          </div>
          <div class="col-sm-4">
            <input type="text" class="form-control" id="fullId" [(ngModel)]="headItem.fullId" readonly>
          </div>
          </div>

          <div class="form-group row">
          <div class="col-sm-2">
            <label for="fullId">Date:</label>
          </div>
          <div class="col-sm-4">
            <div class="input-group">
              <input
                id="fromDate"
                name="fromDate"
                class="form-control"
                placeholder="yyyy-mm-dd"
                [value]="headItem.data | date:'dd.MM.yyyy'"
                (focus)="dp.toggle()"
                ngbDatepicker
                #dp="ngbDatepicker"
                (dateSelect)="onDateSelect($event)"
                readonly
              />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="dp.toggle()" type="button">
                  <i class="bi bi-calendar"></i>
                </button>
                
              </div>
            </div>
          </div>


          </div>
            <div class="form-group row">
              <div class="col-sm-2">
                <label for="client_name">Client:</label>
              </div>
              
              <div class="col-sm-4">
  
              <input
              type="text"
              id="partneriN"
              class="form-control"
              #clientIdInput
              (click)="openClientPopup()"
              (keydown.Space)="openClientPopup()"
              [(ngModel)]="headItem.client_name"
              readonly
              />
        
          </div>
        </div>


        <div class="form-group row" style="width: 100;">
          <div class="col-sm-2">
            <label for="partner_locationN">Client Location:</label>
          </div>
          <div class="col-sm-4">

          <input
          type="text"
          id="partner_locationN"
          class="form-control"
          #clientIdInputLocation
          (click)="openClientLocationsPopup()"
          (keydown.Space)="openClientLocationsPopup()"
          [(ngModel)]="headItem.partner_location_name"
          readonly
          />
    
      </div>
    </div>


    <div class="form-group row" style="width: 100;">
    <div class="col-sm-2">
            <label for="nr_dokumentit">Description:</label>
    </div>
    <div class="col-sm-4">
            <textarea class="form-control" id="nr_dokumentit" rows="3" [(ngModel)]="headItem.nr_dokumentit"></textarea>
     </div>
     </div>
        <ng-container *ngIf="show_create_doc">
          <button type="button" class="btn btn-success" (click)="savedocument(true)">Create Document</button>
        </ng-container>
        <ng-container *ngIf="!show_create_doc">
          <button type="button" class="btn btn-success" (click)="savedocument(false)">Save document</button>
        </ng-container>
           <!-- 
              <label for="fullId">Full ID:</label>
              <input type="text" id="fullId" [(ngModel)]="headItem.fullId" readonly>
           
              <label for="client_name">Client:</label>
              <input type="text" id="client_name" [(ngModel)]="headItem.client_name" readonly>
           
              <label for="partner_location_name">Client Location:</label>
              <input type="text" id="partner_location_name" [(ngModel)]="headItem.partner_location_name" readonly>
            
              <label for="nr_dokumentit">Description:</label>
              <input type="text" id="nr_dokumentit" [(ngModel)]="headItem.nr_dokumentit" readonly>
             -->
          </div>

          <div class="card-body">
              <table class="table">
                <thead class="thead-dark">
                  <tr>
                    <td>Id</td>
                    <td>Name</td>
                    <td>Unit</td>
                    <td>Quantity</td>
                    <td>VAT</td>
                    <td>Discount</td>
                    <td>Price with VAT</td>
                    <td>Total</td>
                    <td>Action</td>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of details">
                      <td>{{ item.idprodukti }}</td>
                      <td>{{ item.item_name }}</td>
                      <td>{{ item.unit_name }}</td>
                      <td>{{ item.sasia | number:'1.2-3' }}</td>
                      <td>{{ item.vat | number:'1.2-2' }}</td>
                      <td>{{ item.discount | number:'1.2-2' }}</td>
                      <td>{{ item.priceWithVat | number:'1.2-2' }}</td>
                      <td>{{ (item.sasia * item.priceWithVat) | number:'1.2-2' }}</td>
                      <td>
                        <button type="button" class="btn btn-danger" (click)="removeRow(item.id)">X</button>
                      </td>
                  </tr>
                </tbody>
              </table>
              <ng-container *ngIf="!show_create_doc">
                <button type="button" class="btn btn-success" (click)="addRow()">Add Row</button>
                </ng-container>
            <div class="text-right mt-3">
              <h4>Total: {{ getTotalAmount() | currency:'DEN ':'symbol-narrow':'1.2-2' }}</h4>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
  