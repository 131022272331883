// mat-cart.component.ts
import { Injectable ,Component, OnInit, Input,ViewChild, ElementRef } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { OutDocsHead,OutDocsDetails } from './outdocs.model'; // Head
import { tmpOutDocsParams } from './tmpOutDocsParams.model';
import { API_ENDPOINTS } from '../api.config';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Observable, throwError  } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientPopupComponent } from '../client/client-popup.component';
import {NgbModule , NgbDate, NgbCalendar, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { getCurrentTimezoneOffsetString } from '../valon.config';
import { AuthService } from '../services/auth.service';

@Component({
  standalone: true,
  selector: 'app-outdocs',
  templateUrl: './outdocs.component.html',
  styleUrls: ['./outdocs.component.css'],
  imports: [CommonModule,FormsModule,NgbModule],
})


export class OutDocsComponent implements OnInit {
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  dp1: any; // Add dp as a property
  dp2: any; // Add dp as a property
  @ViewChild('clientIdInput') clientIdInput!: ElementRef;
  @ViewChild('fromDateInput') fromDateInput!: ElementRef;
  @ViewChild('toDateInput') toDateInput!: ElementRef;
  @ViewChild('languageInput') languageInput!: ElementRef;
  @ViewChild('offsetInput') offsetInput!: ElementRef;
  matOutDocsHead: OutDocsHead[] = [];
  matOutDocsDetails: OutDocsDetails[] = [];
  access:any;
  client_id:number=0;
  isLoading = false;
  constructor(private route: ActivatedRoute,private router: Router,private authService: AuthService,private http: HttpClient, private modalService: NgbModal,private calendar: NgbCalendar, public formatter: NgbDateParserFormatter) {
    const today = calendar.getToday();
    this.fromDate = NgbDate.from({ year: today.year, month: today.month, day: 1 });
    this.toDate = today;
    
   }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      this.matOutDocsHead=[];
      const encodedAccess = params.get('access');
      this.access = encodedAccess ? decodeURIComponent(encodedAccess) : null;
    });
  }
  createNgbDate(year: number,month: number,day: number)
  {
    const ngbDate: NgbDate = new NgbDate(year, month, day);
    return ngbDate;
  }
  onFromDateSelect(date: any): void {
    // Handle the selected date, you can convert it to a string or use it as needed
    this.fromDate = date;
    const formattedDate = this.formatter.format(date);
    console.log(formattedDate);

    // Update your HTTP request or any logic based on the selected date
    //this.fetchMatCartList(formattedDate);
  }
  onToDateSelect(date: any): void {
    // Handle the selected date, you can convert it to a string or use it as needed
    this.toDate = date;
    const formattedDate = this.formatter.format(date);
    console.log(formattedDate);

    // Update your HTTP request or any logic based on the selected date
    //this.fetchMatCartList(formattedDate);
  }
  openClientPopup() {
    const options = { windowClass: 'custom-ngb-modal-window', backdropClass: 'custom-ngb-modal-backdrop',width: '100vw',
    maxWidth: '200vw' };
    const modalRef = this.modalService.open(ClientPopupComponent,options);

    // Subscribe to the client selection event in the popup
    modalRef.componentInstance.clientSelected.subscribe((selectedClient: { id: number, name: string }) => {
        this.clientIdInput.nativeElement.value = selectedClient.name;
        this.client_id=selectedClient.id;
    });
  }

  search(): void {
this.isLoading=true;
    const clientIdString = this.clientIdInput.nativeElement.value;
    const clientId =this.client_id;
    const formattedFromDate = this.fromDate ? this.formatter.format(this.fromDate) : '';
    const formattedToDate = this.fromDate ? this.formatter.format(this.toDate) : '';
    const language = this.languageInput.nativeElement.value;
    const newOffset = getCurrentTimezoneOffsetString();

    /*
    if (clientId === null || clientId === undefined || clientId === 0 || clientId === '') {
      console.error('Client ID is null or undefined. Please select a client.');
      alert('Client ID is null or undefined. Please select a client.');
      return;
    }
    */

    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.getAuthToken(),  // Add your authorization header
      'Content-Type': 'application/json'  // Adjust content type based on your API requirements
    });

  
    const body = {
      client_id:this.client_id,
      fromDate:formattedFromDate,
      toDate:formattedToDate,
      language:'EN',
      offset:newOffset,
      doctype:"",
      year: 2025,
      object_id:'88-03',
      access:this.access
    };
    this.http.post<OutDocsHead[]>(this.authService.getAccountAPI() +  API_ENDPOINTS.vvclient_method + "/getOutDocs", body,{headers })
    .pipe(
      catchError(this.handleError)
    )
    .subscribe(
      data => {
        this.matOutDocsHead = data.result;
        this.isLoading=false;
      },
      error => {
        console.error('Error fetching MatCartList:', error);
        this.isLoading=false;
        alert(error);
      }
      
    );
  }
  private handleError(error: HttpErrorResponse): Observable<any> {
    if (error.status === 401) {
      // Handle 401 Unauthorized error (e.g., redirect to login page)
      console.error('Unauthorized request. Redirecting to login page.');
      alert('Unauthorized request. Redirecting to login page.');
      // You can perform additional actions here, such as redirecting to a login page.
    } else {
      // Handle other errors
      console.error('Error:', error.error);
      alert(error.error);
    }

    // Return an observable with a user-facing error message
    return throwError('Something went wrong. Please try again later.');
  }
  add(id:number,access:string): void {
    this.router.navigate(['/outdocs-open', { id: id,access:access }]);
  }
}

