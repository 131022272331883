export class OutDocsHead {
  IsSelected: boolean = false;
  fullId: string= '';
  id: number = 0;
  account_no: number = 0;
  wYear: number = 0;
  object_id: string = '';
  from_object_id: string = '';
  to_object_id: string = '';
  from_partner_id: number = 0;
  export_from_partner_id: string = '';
  project_id: number = 0;
  projectname: string = '';
  booking_paid_id_ref: number = 0;
  pc_id: number=0;
  table_id: number=0;
  created_by: number=0;
  shifra: number=0;
  dealer_id: number=0;
  data: Date= new Date();
  afati: Date = new Date();
  partneri_id: number = 0;
  client_name: string = '';
  partner_location_id: number =0;
  partner_location_name: string='';
  export_partneri_id: string='';
  kurslistID: number=0;
  kurs_name: string='';
  attach_count: number = 0;
  customer_name: string='';
  nr_dokumentit: string='';
  krijuar: Date = new Date();
  mbyllur: Date = new Date();
  staff_id: number=0;
  user_fullname: string='';
  export_staff_id: string='';
  staff_name: string='';
  visible: number=0;
  doctype: string='';
  doctype_name: string = '';
  doctype_sub: string='';
  posted: boolean=false;
  locked: boolean=false;
  driver_name: string='';
  vehicle_name: string='';
  km: number=0;
  fin_relation_id: string='';
  published: boolean=false;
  mat_export_id: string='';
  sync_filename: string='';
  export_has_update: boolean=false;
  neni32a: boolean=false;
  vat_amount: number=0;
  subtotal: number=0;
  discount_amount: number=0;
  total: number=0;
  total_native: number=0;
  kurs_value: number=0;
  }

  export class OutDocsDetails {
  id: number=0;
  account_no: number=0;
  docDaleseID: number=0;
  object_id: string='';
  wYear: number=0;
  idprodukti: number=0;
  unit_id: number=0;
  item_name: string='';
  unit_name: string='';
  sasia: number=0;
  priceWithoutVat: number=0;
  priceWithVat: number=0;
  discount: number=0;
  discount_value: number=0;
  vat: number=0;
  vat_value: number=0;
  visible: boolean=false;
  DocSellPrice: number=0;
  DocSellPriceWithVat: number=0;
  doctype: string='';
  created: Date=new Date();
  deleted_by: number=0;
  total: number=0;
  }

  