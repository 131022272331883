// auth.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  setLanguage(language: string): void {
    localStorage.setItem('userLanguage', language || '');
  }

  getLanguage(): string | null {
    return localStorage.getItem('userLanguage');
  }

  clearLanguage(): void {
    localStorage.removeItem('userLanguage');
  }
}
