import { Component, OnInit,Renderer2  } from '@angular/core';
import { ProductService } from '../../services/product.service';
import { Product } from '../models/product.model';
import { CartService } from '../../services/cart.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatItems } from '../../items/items-popup.model';
import { Observable, throwError  } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { API_ENDPOINTS } from '../../api.config';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  standalone: true,
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css'],
  imports: [CommonModule,FormsModule],
})
export class ProductListComponent implements OnInit {
  searchTerm: string = '';
  customBearer:any = this.authService.getAuthToken();
  products: MatItems[] = [];
  
  api_local:string = this.authService.getAccountAPI() +  API_ENDPOINTS.vvclient_method;
  access:any;
  constructor(private renderer: Renderer2,private route: ActivatedRoute,private productService: ProductService, private cartService: CartService, private authService:AuthService) {}
  
  ngOnInit(): void {
    
    this.route.queryParamMap.subscribe(params => {
      const encodedAccess = params.get('access');
      this.access = encodedAccess ? decodeURIComponent(encodedAccess) : null;
    
      if(this.access != "" && this.access !=null)
      {
        this.productService.getProducts().pipe(catchError(this.handleError)).subscribe(
          data => {
            this.products = data.result;
          },
          error => {
            console.error('Error fetching MatCartList:', error);
            alert(error);
          }
        );
      }else{
        alert("You have no access!");
      }
    });
  }
  get filteredProducts() {
    if (!this.searchTerm || this.searchTerm.trim() === '') {
      return this.products;
    }
  
    return this.products.filter(product => 
      product.item_name?.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
  }
  trackByFn(index: number, item: { item_name: string }): string {
    return item.item_name; // or any unique identifier
  }
  addToCart(product: MatItems,event: MouseEvent): void {
    this.cartService.addToCart(product);
    console.log('Added to cart:', product);
    const cardElement = (event.target as HTMLElement).closest('.card');
    if (!cardElement) return;
  
    const imgElement = cardElement.querySelector('.product-image') as HTMLElement;
    if (!imgElement) return;
  
    // Clone the image element to create the flying image
    const flyingImg = imgElement.cloneNode(true) as HTMLElement;
    this.renderer.addClass(flyingImg, 'flying');
  
    // Calculate the position, considering the scroll position
    const rect = imgElement.getBoundingClientRect();
    const scrollLeft = window.scrollX || document.documentElement.scrollLeft;
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
  
    this.renderer.setStyle(flyingImg, 'left', `${rect.left + scrollLeft}px`);
    this.renderer.setStyle(flyingImg, 'top', `${rect.top + scrollTop}px`);
    this.renderer.setStyle(flyingImg, 'width', `${imgElement.offsetWidth}px`);
    this.renderer.setStyle(flyingImg, 'height', `${imgElement.offsetHeight}px`);
  
    // Append the flying image to the body
    document.body.appendChild(flyingImg);
  
    // Remove the flying image after animation
    flyingImg.addEventListener('animationend', () => {
      this.renderer.removeChild(document.body, flyingImg);
    });
  }

  private handleError(error: HttpErrorResponse): Observable<any> {
    if (error.status === 401) {
      // Handle 401 Unauthorized error (e.g., redirect to login page)
      console.error('Unauthorized request. Redirecting to login page.');
      alert("Unauthorized request. Redirecting to login page.");
      // You can perform additional actions here, such as redirecting to a login page.
    } else {
      // Handle other errors
      console.error('Error:', error.error);
      alert(error.error);
    }

    // Return an observable with a user-facing error message
    return throwError('Something went wrong. Please try again later.');
  }
  handleImageError(event: Event): void {
    const target = event.target as HTMLImageElement;
    target.src = 'assets/images/default.png'; // Default image path
  }
 
}

