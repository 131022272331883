// src/app/hardware.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HardwareService {

  constructor() { }

  getHardwareId(): string {
    // This is a simple example using the user agent string
    // Real hardware IDs like MAC addresses are not accessible directly from the browser for security reasons
    return navigator.userAgent;
  }
}
