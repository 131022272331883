import { Component, EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  standalone: true,
  selector: 'app-confirmation-popup',
  templateUrl: './success-popup.component.html',
  styleUrls: ['./success-popup.component.css'],
})


export class SuccessDialogComponent {
  constructor(private modalService: NgbModal) { }
  messageName:string="Successfully Added";
  title:string="Congatulations";

  confirm() {
    this.modalService.dismissAll();
  }
  cancel() {
    this.modalService.dismissAll();
  }

}
