<div class="container">
  <div class="store-list">
    <h2>Stores</h2>
    <div *ngFor="let store of stores" class="store-item" (click)="showConnections(store.id)">
      {{ store.store_name }}
    </div>
  </div>

  <div class="connection-list" *ngIf="connections && connections.length > 0">
    <h2>Connections</h2>
    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Account Token</th>
          <th>Identity</th>
          <th>API URL</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let conn of connections">
          <td>{{ conn.account_no }}</td>
          <td>{{ conn.account_token }}</td>
          <td>{{ conn.identity || 'N/A' }}</td>
          <td>{{ conn.api_url }}</td>
          <a [href]="getEncodedUrl(conn.api_url || '',conn.account_token || '', conn.identity)">Login</a>
        </tr>
      </tbody>
    </table>
  </div>
</div>
