<div class="popup-container" (keydown.esc)="closePopup()">
  <table class="table">
    <thead class="thead-dark">
      <tr>
        <th scope="col">Id</th>
        <th scope="col">Name</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of matClientsLocations" (click)="selectClient(item.id,item.location_name)" style="cursor:pointer">
        <td>{{ item.id }}</td>
        <td>{{ item.location_name }}</td>
      </tr>
    </tbody>
  </table>
  <div style="text-align: center;" *ngIf="isLoading">
    <span class="spinnerplace" >
      <i class="spinner"></i> Loading...
    </span>
  </div>
</div>