import { Injectable } from '@angular/core';
import { MatItems } from '../items/items-popup.model';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  private cart: MatItems[] = [];
  private readonly cartSessionKey = 'cart';
  private cartCountSubject = new BehaviorSubject<number>(0);
  cartCount$ = this.cartCountSubject.asObservable();

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
   
  }
  ngOnInit(): void {
    this.loadCartFromSession();
  }

  addToCart(product: MatItems): void {
    const existingProduct = this.cart.find(item => item.idfood_items === product.idfood_items);

    if (existingProduct) {
      // Increment the quantity of the existing product
      existingProduct.quantity += 1;
    } else {
      // Add new product with quantity set to 1
      product.quantity = 1;
      this.cart.push(product);
    }

    this.saveCartToSession();
    this.updateCartCount();
  }

  private updateCartCount(): void {
    this.cartCountSubject.next(this.cart.length);
  }

  getCart(): MatItems[] {
    this.loadCartFromSession();
    this.updateCartCount();
    return this.cart;
  }

  removeFromCart(product: MatItems): void {
    this.cart = this.cart.filter(item => item.idfood_items !== product.idfood_items);
    this.saveCartToSession();
    this.updateCartCount();
  }
  clearCart(): void {
    this.cart = [];
    this.saveCartToSession();
    this.updateCartCount();
  }

  private saveCartToSession(): void {
    if (this.isSessionStorageAvailable()) {
    sessionStorage.setItem(this.cartSessionKey, JSON.stringify(this.cart));
    }
  }

  private loadCartFromSession(): void {
    if (this.isSessionStorageAvailable()) {
    const cartSession = sessionStorage.getItem(this.cartSessionKey);
    if (cartSession) {
      this.cart = JSON.parse(cartSession);
    }
  }
  }
  getCartCount(): number {
    this.loadCartFromSession();
    return this.cart.length;
  }
  isSessionStorageAvailable(): boolean {
    return isPlatformBrowser(this.platformId) && typeof sessionStorage !== 'undefined';
  }
}
