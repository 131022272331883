// api.config.ts
//const endpoint = 'https://10.10.36.178:5001';
const endpoint = 'https://vaquita.cc:9024';
//const defaultEndpoint = `${endpoint}/api/VVClient`;
const vvclinetMethod = `/api/VVClient`;
const multistoreEndpoint = `${endpoint}/api/Multistore`;

export const API_ENDPOINTS = {
    endpoint: endpoint,
    //default: defaultEndpoint,
    multistore:multistoreEndpoint,
    vvclient_method : vvclinetMethod,
    //connection_token : 'kgJNq6D5pcWlmrL2pdBkUQ==',
    account_token : 'dQwsmZH8WR3GWTDoK9L8kA==',
    connection_identity : 'zq73n7al4uRAumlQ/5kD5yVdAgX+kZk4bg8xQTGP/KLQcITrRKgWtPIr7pyieiMUC6mfnxztJoj/rn3deLlSP1iAfLhVAg1mo4SpOHC4qlhjL/0CRcM4Jbzq8qzenfQ6'
    // Add other API endpoints as needed
  };
  