// auth.service.ts
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

class JobPositionAccess {
  id: number = 0;
  account_no: number=0;
  job_position_id: number=0;
  form_id: string='';
  doctype: string=''; // You can specify the type if known, otherwise use `any`
  read: boolean=false;
  write: boolean=false;
  delete: boolean=false;
  self: boolean=false;
  description: string=''; // You can specify the type if known, otherwise use `any`
  enc: string = '';
}

@Injectable({
  providedIn: 'root'
})
export class AccessService {
  jobPositionsAccessAll : any[]= [];
  setAccess(data: any): void {
    this.jobPositionsAccessAll = data;
    localStorage.setItem('accessData', JSON.stringify(data) || '');
  }
  
  getAccessAll(): any[] {
    const storedData = localStorage.getItem('accessData');
    if (storedData) {
      try {
        return JSON.parse(storedData);
      } catch (error) {
        console.error('Error parsing stored data:', error);
        return [];
      }
    } else {
      return [];
    }
  }

  clearAccessData(): void {
    localStorage.removeItem('accessData');
  }
  filterDataByFormId(formId: string) {
    this.checkIfFilled();
    return this.jobPositionsAccessAll.filter((access:JobPositionAccess ) => access.form_id === formId);
  }
  filterDataByDoctype(pDoctype: string) {
    this.checkIfFilled();
    //console.log(this.jobPositionsAccessAll);
    let access = this.jobPositionsAccessAll.filter((access:JobPositionAccess ) => access.doctype === pDoctype);
    return access;
  }
  filterDataByEnc(pEnc: string | null) : Array<JobPositionAccess> {
    this.checkIfFilled();
    //console.log(this.jobPositionsAccessAll);
    let access = this.jobPositionsAccessAll.filter((access:JobPositionAccess ) => access.enc === pEnc);
    return access;
  }

  checkIfFilled()
  {
    if (typeof this.jobPositionsAccessAll === 'undefined' || this.jobPositionsAccessAll.length === 0) {
      this.jobPositionsAccessAll = this.getAccessAll();
    } else {
      //console.log('jobPositionsAccessAll is defined');
    }
  }
}
