import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';
import { AccessService } from './services/access.service';
import { HttpParameterCodec } from "@angular/common/http";
import { CartService } from './services/cart.service';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterModule,RouterOutlet,HttpClientModule,CommonModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  title = 'VVClient';
  cartCount: number = 0;

  constructor(private authService: AuthService,public accessSerive: AccessService,private router: Router,private cartService: CartService) {
   
    //alert(accessSerive.filterDataByDoctype('OUT21')[0].enc);
  }
  ngOnInit(): void {
   
    this.cartService.cartCount$.subscribe(count => {
      this.cartCount = count;
    });
    this.cartCount = this.cartService.getCartCount();
  }

  isLogin(){
    return this.authService.isAuthenticated() 
    //if(this.router.url === '/login')
      //return false;
    //else if(this.authService.isAuthenticated()==false && this.router.url === '/'){
      //this.router.navigate(['/login']);
      //return false;

    //}
  }
  logout()
  {
    let api = this.authService.getAccountAPI();
    let acc = this.authService.getAccountToken();
    let idt = this.authService.getAccountIdentity();
    this.authService.clearAuthToken();
    this.router.navigate(['/login'], {
      queryParams: {
        api_call : encodeURIComponent(api),
        account_token: encodeURIComponent(acc),
        account_identity: encodeURIComponent(idt)
      }
    });
  }
  isAuthenticated()
  {
    return this.authService.isAuthenticated();
  }
  isAuthenticatedTwo(formId:string)
  {
    return this.authService.isAuthenticated() && this.hasReadAccessFromFormId(formId);
  }
  getDocTypeFromDoctype(doctype:string)
  {
    let dtype =this.accessSerive.filterDataByDoctype(doctype)[0].doctype;
    return dtype;
  }
  getEncFromDoctype(doctype:string)
  {
    var access = this.accessSerive.filterDataByDoctype(doctype);
    if(access.length > 0)
    {
      let enc =access[0].enc;
      return enc;
    }
      
      //return dtype;
      return '';
  }
  getEncFromFormId(formId:string)
  {
    var access = this.accessSerive.filterDataByFormId(formId);
    if(access.length > 0)
    {
      let enc =access[0].enc;
      return enc;
    }
      return '';
  }
  hasReadAccessFromFormId(formId:string)
  {
    var access = this.accessSerive.filterDataByFormId(formId);
    if(access.length > 0)
    {
      let acc =access[0].read;
      return acc;
    }
      return '';
  }
  hasWriteAccessFromFormId(formId:string)
  {
    var access = this.accessSerive.filterDataByFormId(formId);
    if(access.length > 0)
    {
      let acc =access[0].write;
      return acc;
    }
      return '';
  }
  hasDeleteAccessFromFormId(formId:string)
  {
    var access = this.accessSerive.filterDataByFormId(formId);
    if(access.length > 0)
    {
      let acc =access[0].delete;
      return acc;
    }
      return '';
  }
  getDescriptionFromDoctype(doctype:string)
  {
    var access = this.accessSerive.filterDataByDoctype(doctype);
    if(access.length > 0)
    {
      let dtype =access[0].description;
      return dtype;
    }
      
      //return dtype;
      return '';
  }
  encodeURIComponentC(data:any) : string | null
  {
    let retval = encodeURIComponent(data)
    return retval;
  }
}
