// login.component.ts
import { Injectable ,Component, OnInit, Input,ViewChild, ElementRef } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { MatCart1 } from './multistore-login.model'; // Import the C# model
import { tmpLoginParams } from './tmpLoginParams.model';
import { MatStores } from '../login/mat-stores.model';
import { API_ENDPOINTS } from '../api.config';
import { Observable, throwError  } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { getCurrentTimezoneOffsetString } from '../valon.config';
import { AuthService } from '../services/auth.service';
import { RouterModule,Router } from '@angular/router';
import { AccessService } from '../services/access.service';
import { LanguageService } from '../services/language.service';
import { HardwareService } from '../hardware.service';

@Component({
  standalone: true,
  selector: 'app-multistore-login',
  templateUrl: './multistore-login.component.html',
  styleUrls: ['./multistore-login.component.css'],
  imports: [CommonModule,FormsModule,NgbModule,RouterModule]
})


export class MultistoreLoginComponent implements OnInit {
  loginParams: tmpLoginParams = {
    username: '',
    password: ''
  };
  matStores: MatStores[] = [];
  hardwareId: string;
  constructor(private http: HttpClient,private authService: AuthService, private accessSerive: AccessService,private languageService: LanguageService,private router: Router,private hardwareService: HardwareService) {
    this.hardwareId = this.hardwareService.getHardwareId();
   }

  ngOnInit(): void {
    //if(this.authService.isAuthenticated())
    //{
      //this.router.navigate(['/']);
    //}
  } 

  onLoginSubmit(): void {

    const newOffset = getCurrentTimezoneOffsetString();

    // Validate Username
    if (this.loginParams.username === null || this.loginParams.username === undefined || this.loginParams.username === '') {
      console.error('Username is null or undefined.');
      alert('Username is null or undefined.');
      return;
    }
    // Validate Password
    if (this.loginParams.password === null || this.loginParams.password === undefined || this.loginParams.password === '') {
      console.error('Password is null or undefined.');
      alert('Password is null or undefined.');
      return;
    }
    const user_language ='EN';
  
    const body = {
      email:this.loginParams.username,
      password:this.loginParams.password,
      device:'web',
      hardware_id:this.hardwareId
    };
    this.http.post<any>(API_ENDPOINTS.multistore + "/Authorize", body)
    .pipe(
      catchError(this.handleError)
    )
    .subscribe(
      data => {
        if(data.status === false)
        {
          alert('Wrong username or password!');
        }
        else
        {
          console.log(JSON.stringify(data));
          console.log(JSON.stringify(data.token));
          this.languageService.setLanguage(user_language);
          this.authService.setMultistoreAuthToken(data.token);
          this.authService.setMultistoreResults(JSON.stringify(data));
          //this.accessSerive.setAccess(data.result.job_positions_access);

          this.router.navigate(['/multistore-selector']);
          
        }
      },
      error => {
        console.error('Error fetching MatCartList:', error);
        alert(error);
      }
    );
  }
  private handleError(error: HttpErrorResponse): Observable<any> {
    if (error.status === 401) {
      // Handle 401 Unauthorized error (e.g., redirect to login page)
      console.error('Unauthorized request. Redirecting to login page.');
      alert('Unauthorized request. Redirecting to login page.');
      // You can perform additional actions here, such as redirecting to a login page.
    } else {
      // Handle other errors
      console.error('Error:', error.error);
      alert(error.error);
    }

    // Return an observable with a user-facing error message
    return throwError('Something went wrong. Please try again later.');
  }

}
