<div class="container mt-5 fade-in">
  <!-- Search Box -->
  <div class="row mb-4">
    <div class="col-md-12">
      <input 
        type="text" 
        class="form-control" 
        placeholder="Search products..." 
        [(ngModel)]="searchTerm" 
        aria-label="Search"
      />
    </div>
  </div>

  <!-- Product Grid -->
  <div class="row">
    <div class="col-lg-3 col-md-4 col-sm-6 mb-4" *ngFor="let product of filteredProducts; trackBy: trackByFn">
      <div class="card h-100 border-0 shadow-sm mb-3 position-relative">
        <img
          [src]="api_local + product.automated_image_path + '&customBearer=' + customBearer"
          class="card-img-top rounded product-image"
          alt="{{ product.item_name }}"
          (error)="handleImageError($event)"
          style="object-fit: cover; height: 220px;"
        />
        <div class="card-body text-center">
          <h5 class="card-title mb-3">{{ product.item_name }}</h5>
          <p class="card-text text-muted mb-4">{{ product.item_sell_price | currency }}</p>
          <button  (click)="addToCart(product, $event)">
            <i class="bi bi-cart-plus"></i> Add to Cart
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
