<!-- login.component.html -->
<div class="container d-flex justify-content-center align-items-center min-vh-100">
  <div class="row justify-content-center w-100  mb-5">
    <div class="col-lg-4 col-md-6">
      <div class="card shadow-lg border-0 rounded-lg d-flex flex-column justify-content-center align-items-center" style="min-height: 300px;">
        <div class="card-header bg-gradient-primary text-center w-100">
          <!-- Images centered horizontally -->
          <div class="d-flex justify-content-center align-items-center">
            <img src="https://vvircompany.com/assets/img/vlogo.svg" width="100" height="100" alt="Company Logo" class="img-fluid mb-3" style="max-width: 100px;">
            <h4 class="mb-0 mx-3">&</h4> <!-- Added mx-3 for some horizontal spacing -->
            <img src="https://benlind.eu/logo.png" alt="Company Logo" width="200" height="200" class="img-fluid mb-3" style="max-width: 125px;">
          </div>
          
          
          <h4 class="mb-0">Sign In</h4>
        </div>
        <div class="card-body p-4 w-100">
          <form>
            <div class="form-group mb-3">
              <label for="username" class="form-label">Username</label>
              <input type="text" class="form-control form-control-lg" id="username" name="username" [(ngModel)]="loginParams.username" placeholder="Enter your username" required>
            </div>
            <div class="form-group mb-4">
              <label for="password" class="form-label">Password</label>
              <input type="password" class="form-control form-control-lg" id="password" name="password" [(ngModel)]="loginParams.password" placeholder="Enter your password" required>
            </div>
            <div class="d-grid">
              <button type="button" style="background-color:#2070E3;color: white;" (click)="onLoginSubmit()">
                <span *ngIf="!isLoading">Login</span>
                <span class="spinnerplace" *ngIf="isLoading">
                  <i class="spinner"></i> Loading...
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

