import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import { CartService } from '../../services/cart.service';
import { MatItems } from '../../items/items-popup.model';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { API_ENDPOINTS } from '../../api.config';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { RouterModule,Router } from '@angular/router';
import { AccessService } from '../../services/access.service';
import { LanguageService } from '../../services/language.service';
import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { HardwareService } from '../../hardware.service';
import { throwError } from 'rxjs/internal/observable/throwError';
import { Observable } from 'rxjs/internal/Observable';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientPopupComponent } from '../../client/client-popup.component';
import { SuccessDialogComponent } from '../../popups/successfully-popup/success-popup.component';
@Component({
  standalone: true,
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css'],
  imports: [CommonModule,FormsModule],
})

export class CartComponent implements OnInit {
  isListView = true; // Default to list view
  isInProcess=false;
  toggleView() {
    this.isListView = !this.isListView;
  }

  openFilter() {
    // Implement your filter logic here
  }
  @ViewChild('clientIdInput') clientIdInput!: ElementRef;
  cart: MatItems[] = [];
  access:any;
  hardwareId: string;
  client_id:number=0;
  isLoading = false;
  constructor(private http: HttpClient,private authService: AuthService,private accessSerive: AccessService,private languageService: LanguageService,private route: ActivatedRoute,private modalService: NgbModal,private router: Router,private cartService: CartService,private hardwareService: HardwareService) { 
    this.hardwareId = this.hardwareService.getHardwareId();
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      const encodedAccess = params.get('access');
      this.access = encodedAccess ? decodeURIComponent(encodedAccess) : null;
    
      if(this.access != "" && this.access !=null)
      {
        this.cart = this.cartService.getCart();
      }else{
        alert("You have no access!");
      }
    });
    //alert(this.cart.length);
  }
  openClientPopup() {
    
    const options = { windowClass: 'custom-ngb-modal-window', backdropClass: 'custom-ngb-modal-backdrop',width: '100vw',
    maxWidth: '200vw' };
    const modalRef = this.modalService.open(ClientPopupComponent,options);
    
    // Subscribe to the client selection event in the popup
    modalRef.componentInstance.clientSelected.subscribe((selectedClient: { id: number, name: string }) => {
      
      this.clientIdInput.nativeElement.value = selectedClient.name;
      this.client_id=selectedClient.id;
    });
  }
  submitTheOrder():void{
   if(this.client_id==0){
    alert("Please you must select Client");
    return;
   }
   if(this.cart.length==0){
    alert("You must select at least one product!");
    return;
   }
   if(this.isInProcess==true){
    alert("Already In Process");
    return;
   }
   this.isLoading=true;
   this.isInProcess=true;
    const user_language ='EN';
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.getAuthToken(),  // Add your authorization header
      'Content-Type': 'application/json'  // Adjust content type based on your API requirements
    });
    const body = {
     access:this.access,
     client_id:this.client_id,
     products: this.cart
    };
    this.http.post<any>(this.authService.getAccountAPI() +  API_ENDPOINTS.vvclient_method + "/setProceedToCheckout", body,{headers})
    .pipe(
      catchError(this.handleError)
    )
    .subscribe(
      data => {
        if(data.status === false)
        {
          alert("Something went wrong");
          this.isInProcess=false;
          this.isLoading=false;
        }
        else
        {
          this.isInProcess=false;
          this.isLoading=false;
          this.cartService.clearCart();
                this.cart=this.cartService.getCart();
          const options = { windowClass: 'custom-ngb-modal-window', backdropClass: 'custom-ngb-modal-backdrop',width: '100vw',
            maxWidth: '200vw' };
            const modalRef = this.modalService.open(SuccessDialogComponent,options);
            
            modalRef.componentInstance.title = "Congratulations";
            modalRef.componentInstance.messageName = "Reservation Completed Successfully! If you want our delivery guy to be on a good mood pay the previous order :)";
        }
      },
      error => {
        console.error('Error fetching MatCartList:', error);
        alert(error);
      }
    );
  }
  private handleError(error: HttpErrorResponse): Observable<any> {
    if (error.status === 401) {
      // Handle 401 Unauthorized error (e.g., redirect to login page)
      console.error('Unauthorized request. Redirecting to login page.');
      alert('Unauthorized request. Redirecting to login page.');
      // You can perform additional actions here, such as redirecting to a login page.
    } else {
      // Handle other errors
      console.error('Error:', error.error);
      alert(error.error);
    }

    // Return an observable with a user-facing error message
    return throwError('Something went wrong. Please try again later.');
  }
  removeFromCart(product: MatItems): void {
    this.cartService.removeFromCart(product);
    this.cart = this.cartService.getCart(); // Refresh the cart items
  }

  getTotal(): string {
    return this.cart.reduce((total, product) => total + product.item_sell_price, 0).toFixed(2);
  }
}
