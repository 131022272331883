// mat-cart.component.ts
import { Injectable ,Component, OnInit, Input,ViewChild, ElementRef,EventEmitter,Output } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { API_ENDPOINTS } from '../api.config';
import { Observable, throwError  } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {MatItems} from './items-popup.model';
import { AuthService } from '../services/auth.service';


@Component({
  standalone: true,
  selector: 'app-items-popup',
  templateUrl: './items-popup.component.html',
  styleUrls: ['./items-popup.component.css'],
  imports: [CommonModule,FormsModule],
})


export class ItemsPopupComponent implements OnInit {
  matItems: MatItems[] = [];
  constructor(private authService: AuthService,private http: HttpClient, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.search("","");
  }

  @Output() itemSelected: EventEmitter<{ id: number, name: string }> = new EventEmitter<{ id: number, name: string }>();

  selectItem(clientId: number,clientName:string) {
    // Emit the selected client's id
    this.itemSelected.emit({ id: clientId, name: clientName });
    this.closePopup();
  }
  closePopup() {
    // Close the currently opened modal
    this.modalService.dismissAll();
  }
  closeOnOverlayClick(event: Event): void {
    // Use type assertion to inform TypeScript about classList
    const target = event.target as HTMLElement;

    // Check if the click target is the overlay (outside of the modal content)
    if (target && target.classList && target.classList.contains('modal')) {
      this.closePopup();
    }
  }
  search(search: string, client_type:string): void {

    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.getAuthToken(),  // Add your authorization header
      'Content-Type': 'application/json'
    });

  
    const body = {
      search,
      client_type
    };
    this.http.post<MatItems[]>(this.authService.getAccountAPI() +  API_ENDPOINTS.vvclient_method + "/GetItems", body,{headers })
    .pipe(
      catchError(this.handleError)
    )
    .subscribe(
      data => {
        //alert(JSON.stringify(data));
        this.matItems = data.result;
      },
      error => {
        console.error('Error fetching MatCartList:', error);
        alert(error);
      }
    );
  }
  private handleError(error: HttpErrorResponse): Observable<any> {
    if (error.status === 401) {
      // Handle 401 Unauthorized error (e.g., redirect to login page)
      console.error('Unauthorized request. Redirecting to login page.');
      alert("Unauthorized request. Redirecting to login page.");
      // You can perform additional actions here, such as redirecting to a login page.
    } else {
      // Handle other errors
      console.error('Error:', error.error);
      alert(error.error);
    }

    // Return an observable with a user-facing error message
    return throwError('Something went wrong. Please try again later.');
  }

}
