<div class="popup-container" (keydown.esc)="closePopup()">
  <table class="table">
    <thead class="thead-dark">
      <tr>
        <th scope="col">Id</th>
        <th scope="col">Name</th>
        <!-- <th scope="col">Address</th>
        <th scope="col">Edb</th>
        <th scope="col">Email</th> -->
        <th scope="col">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of matItems" (click)="selectItem(item.idfood_items,item.item_name)">
        <td>{{ item.idfood_items }}</td>
        <td>{{ item.item_name }}</td>
        <!-- <td>{{ item.address}}</td>
        <td>{{ item.edb }}</td>
        <td>{{ item.email }}</td> -->
        <td>
          <!-- Add a button to select the row and emit the client_id -->
          <button class="btn btn-primary" (click)="selectItem(item.idfood_items,item.item_name)">Select</button>
        </td>
      </tr>
    </tbody>
  </table>
</div>