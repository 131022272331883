import { Injectable ,Component, OnInit, Input,ViewChild, ElementRef, ChangeDetectorRef  } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { OutDocsHead,OutDocsDetails } from './outdocs.model'; // Head
import { tmpOutDocsParams } from './tmpOutDocsParams.model';
import { ClientLocationsPopupComponent } from '../client/client-locations-popup.component';
import { API_ENDPOINTS } from '../api.config';
import { Router } from '@angular/router';
import { Observable, throwError  } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { DatePipe } from '@angular/common';
import { FormBuilder,FormGroup, FormsModule } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientPopupComponent } from '../client/client-popup.component';
import {NgbModule , NgbDate, NgbCalendar, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { getCurrentTimezoneOffsetString } from '../valon.config';
import { AuthService } from '../services/auth.service';
import { ItemsPopupComponent } from '../items/items-popup.component';
import { InputQuantityComponent } from './inputs/input-quantity.component';
import { Subject } from 'rxjs';
import { ModalParameters } from '../client/ModalParameters.model';
import { ConfirmationDialogComponent } from '../popups/confirmation-popup.component';
import { AccessService } from '../services/access.service';
import { SuccessDialogComponent } from '../popups/successfully-popup/success-popup.component';

@Component({
  selector: 'app-outdocs-open',
  standalone: true,
  imports: [CommonModule,FormsModule,NgbModule],
  templateUrl: './outdocs-open.component.html',
  styleUrl: './outdocs-open.component.css',
  providers: [DatePipe]
})
export class OutdocsOpenComponent {
  constructor(private modalParameters: ModalParameters,private accessSerive: AccessService,private route: ActivatedRoute,private router: Router,private http: HttpClient,private modalService: NgbModal,private modalService2: NgbModal,private authService: AuthService,private cdRef: ChangeDetectorRef,private calendar: NgbCalendar, public formatter: NgbDateParserFormatter,private datepipe: DatePipe)
  {
    const today = calendar.getToday();
    this.headItem.data = new Date(today.year, today.month - 1, today.day);
  }
  head_id : number = 0;
  doctype:any;
  access:any;
  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      // Get the value of the 'id' parameter
      let a  =params.get('id');
      if (a !== null) {
        // It's safe to assign the value to a number variable
        this.head_id = parseInt(a, 10);
      } else {
        // Handle the case where the value is null, provide a default value, or throw an error
        this.head_id = 0; // Default value, adjust as needed
      }
      this.show_create_doc = this.head_id<=0;
      //let doctype = params.get('doctype');
      //this.doctype = doctype;
      let access = params.get('access');
      this.access = access;
      //alert(doctype);
      this.loadHead(this.head_id,access,2025,'01');
      this.loadDetails(this.head_id);
    });
    
  }
  createNgbDate(year: number,month: number,day: number)
  {
    const ngbDate: NgbDate = new NgbDate(year, month, day);
    return ngbDate;
  }
  onDateSelect(date: any): void {
    // Handle the selected date, you can convert it to a string or use it as needed
    this.headItem.data = date;
    const formattedDate = this.formatter.format(date);
    console.log(formattedDate);

    // Update your HTTP request or any logic based on the selected date
    //this.fetchMatCartList(formattedDate);
  }
  headItem: OutDocsHead = new OutDocsHead();
  show_create_doc:boolean = false;
  head: OutDocsHead[]=[];
  details: OutDocsDetails[]=[];
  displayedColumns: string[] = [];
  lastSelectedRowIndex: number | null = null;

  loadHead(pHeadId:number,pAccess:string | null,pYear:number,pObjectId:string): void {

    if(pHeadId<=0)
    {
      this.loadDoctype(pAccess);
    }

    const newOffset = getCurrentTimezoneOffsetString();
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.getAuthToken(),  // Add your authorization header
      'Content-Type': 'application/json'  // Adjust content type based on your API requirements
    });

  
    const body = {
      head_id:pHeadId,
      fromDate:"2000-01-01",
      toDate:"2025-01-17",
      language:'EN',
      offset:newOffset,
      access:pAccess,
      year: pYear,
      object_id:pObjectId
    };
    this.http.post<OutDocsHead[]>(this.authService.getAccountAPI() +  API_ENDPOINTS.vvclient_method + "/getOutDocs", body,{headers })
    .pipe(
      catchError(this.handleError)
    )
    .subscribe(
      data => {
        this.head = data.result;
        //alert(JSON.stringify(this.head[0]))
        if (this.head.length > 0) {
          // Retrieve the first element
          
          this.headItem = this.head[0];
         
          // Now you can use the 'firstRow' variable as needed
          //alert('First row:'+ firstRow.client_name);
        }
      },
      error => {
        console.error('Error fetching MatCartList:', error);
        alert(error);
      }
    );
  }
  loadDetails(pHeadId:number): void {
    const newOffset = getCurrentTimezoneOffsetString();

    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.getAuthToken(),  // Add your authorization header
      'Content-Type': 'application/json'  // Adjust content type based on your API requirements
    });

  
    const body = {
      docdalese_id:pHeadId,
      access:this.access,
      year: 2025,
      object_id:'01'
    };
    this.http.post<Observable<OutDocsDetails[]>>(this.authService.getAccountAPI() +  API_ENDPOINTS.vvclient_method + "/getOutDocsDetails", body,{headers })
    .pipe(
      catchError(this.handleError)
    )
    .subscribe(
      data => {
        //alert(JSON.stringify(data));
        this.details = data;
        this.cdRef.detectChanges(); // Trigger change detection
      },
      error => {
        console.error('Error fetching MatCartList:', error);
        alert(error);
      }
    );
  }
  loadDoctype(pAccess:string | null): void {
    
    var access= this.accessSerive.filterDataByEnc(pAccess);
    if(access.length>0)
    {
      this.headItem.doctype_name = access[0].description;
    }
    /*const newOffset = getCurrentTimezoneOffsetString();

    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.getAuthToken(),  // Add your authorization header
      'Content-Type': 'application/json'  // Adjust content type based on your API requirements
    });

  
    const body = {
      doctype:pDoctype,
      language:'EN',
      print_language:'MK'
    };
    this.http.post<OutDocsHead[]>(API_ENDPOINTS.default + "/getDoctypeInfo", body,{headers })
    .pipe(
      catchError(this.handleError)
    )
    .subscribe(
      data => {
        this.headItem.doctype_name = data.result[0].description;
      },
      error => {
        console.error('Error fetching MatCartList:', error);
        alert(error);
      }
    );*/
  }
  
  savedocument(insert:boolean)
  {
    

    
    const newOffset = getCurrentTimezoneOffsetString();

    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.getAuthToken(),  // Add your authorization header
      'Content-Type': 'application/json'  // Adjust content type based on your API requirements
    });
    let dataa: string = this.datepipe.transform(this.headItem.data, 'yyyy-MM-dd') as string;
    //const dataa = this.datepipe.transform(this.headItem.data, 'yyyy-MM-dd');
    const body = {
      head_id: this.head_id,
      date:dataa,
      access:this.access,
      wyear: 2025,
      object_id:'01',
      description:this.headItem.nr_dokumentit,
      partneri_id: this.headItem.partneri_id,
      partner_location_id: this.headItem.partner_location_id
    };
    this.http.post<Observable<OutDocsDetails[]>>(this.authService.getAccountAPI() +  API_ENDPOINTS.vvclient_method + "/setOutDocs", body,{headers })
    .pipe(
      catchError(this.handleError)
    )
    .subscribe(
      data => {
        const options = { windowClass: 'custom-ngb-modal-window', backdropClass: 'custom-ngb-modal-backdrop',width: '100vw',
          maxWidth: '200vw' };
          const modalRef = this.modalService.open(SuccessDialogComponent,options);
          modalRef.componentInstance.title = "Congratulations";

        //alert(data.docdalese_id);
        this.head_id = data.docdalese_id;
        if(this.head_id>0)
        {
          modalRef.componentInstance.messageName="Updated successfully!";
          this.show_create_doc= false;
          this.headItem.fullId = data.fullId;
          this.headItem.shifra = data.shifra;
        }else{
          modalRef.componentInstance.messageName = "Created Successfully!";
        }
        //alert(JSON.stringify(data));
        //this.details = data;
       
        this.cdRef.detectChanges(); // Trigger change detection
      },
      error => {
        console.error('Error fetching MatCartList:', error);
        alert(error);
      }
    );
  }

  addRow() {
    this.openItemsPopup();
  }
  
  openItemsPopup() {
    let prompted = false;
    const completionSubject = new Subject<void>();
    const options = { windowClass: 'custom-ngb-modal-window', backdropClass: 'custom-ngb-modal-backdrop',width: '100vw',
    maxWidth: '200vw' };
    const modalRef = this.modalService.open(ItemsPopupComponent,options);
    modalRef.componentInstance.itemSelected.subscribe((selecteditem: { id: number, name: string }) => {
      if(prompted==true)
      return;
      const quantityInp = prompt('Please enter quantity:');
      prompted = true;

      
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + this.authService.getAuthToken(),  // Add your authorization header
        'Content-Type': 'application/json'  // Adjust content type based on your API requirements
      });
  
      //alert(this.headItem.shifra);
      const body = {
        wYear:2025,
        access:this.access,
        idprodukti:selecteditem.id,
        sasia:quantityInp,
        docdalese_id:this.head_id,
        shifra: this.headItem.shifra
      };
      this.http.post<OutDocsHead[]>(this.authService.getAccountAPI() +  API_ENDPOINTS.vvclient_method + "/setOutDocsDetails", body,{headers })
      .pipe(
        catchError(this.handleError)
      )
      .subscribe(
        data => {
          this.loadDetails(this.head_id);
          //alert(data.status);
        },
        error => {
          console.error('Error fetching MatCartList:', error);
          alert(error);
        }
      );
    });

    
    
  }

  openConfirmationDialog(rowId: number) {
    const options = { windowClass: 'custom-ngb-modal-window', backdropClass: 'custom-ngb-modal-backdrop',width: '100vw',
    maxWidth: '200vw' };
    const modalRef = this.modalService.open(ConfirmationDialogComponent,options);
    modalRef.componentInstance.confirmed.subscribe((confirmed: boolean) => {
      if (confirmed) {
        // Delete action
        const headers = new HttpHeaders({
          'Authorization': 'Bearer ' + this.authService.getAuthToken(),  // Add your authorization header
          'Content-Type': 'application/json'  // Adjust content type based on your API requirements
        });
    
      
        const body = {
          id:rowId,
          access:this.access
          //shifra:2,
        };
        this.http.post<OutDocsHead[]>(this.authService.getAccountAPI() +  API_ENDPOINTS.vvclient_method + "/removeOutDocsDetails", body,{headers })
        .pipe(
          catchError(this.handleError)
        )
        .subscribe(
          data => {
            this.loadDetails(this.head_id);
            //alert(data.status);
          },
          error => {
            console.error('Error fetching result:', error);
            alert(error);
          }
        );

        this.modalService.dismissAll();
      }
      else
      {
        this.modalService.dismissAll();
      }
    });
    
  }
  removeRow(rowId: number) {
    this.openConfirmationDialog(rowId);
  }

  updateTotalAmount() {
    let totalAmount = 0;
    for (const item of this.details) {
      item.total = item.sasia * item.priceWithVat;
      totalAmount += item.total || 0;
    }
    return totalAmount;
  }

  getTotalAmount() {
    return this.updateTotalAmount();
  }
  selectRow(index: number) {
    this.lastSelectedRowIndex = index;
    // You can perform additional actions here when a row is selected
  }
  getValueFromInputName(inputName: string, inputIndex:number): any {
    // Implement logic to get the value from the input name
    // This could involve using ngModel or directly accessing the input element
    // Here's a simple example assuming ngModel is used
    // const inputIndex = inputName.split('_')[1];
    return this.details[inputIndex].idprodukti;
  }
  openClientPopup() {
    const options = { windowClass: 'custom-ngb-modal-window', backdropClass: 'custom-ngb-modal-backdrop',width: '100vw',
    maxWidth: '200vw' };
    const modalRef = this.modalService.open(ClientPopupComponent,options);

    // Subscribe to the client selection event in the popup
    modalRef.componentInstance.clientSelected.subscribe((selectedClient: { id: number, name: string }) => {
      this.headItem.partneri_id = selectedClient.id;
      this.headItem.client_name = selectedClient.name;
      this.headItem.partner_location_id = 0;
      this.headItem.partner_location_name = '';
    });
  }
  openClientLocationsPopup() {

    this.modalParameters.customNumber = this.headItem.partneri_id;

    const options = { windowClass: 'custom-ngb-modal-window', backdropClass: 'custom-ngb-modal-backdrop',width: '100vw',
    maxWidth: '200vw' };
    const modalRef = this.modalService.open(ClientLocationsPopupComponent,options);

    // Subscribe to the client selection event in the popup
    modalRef.componentInstance.clientSelected.subscribe((selectedClient: { id: number, name: string }) => {
      this.headItem.partner_location_id = selectedClient.id;
      this.headItem.partner_location_name = selectedClient.name;
    });
  }
  private handleError(error: HttpErrorResponse): Observable<any> {
    if (error.status === 401) {
      // Handle 401 Unauthorized error (e.g., redirect to login page)
      console.error('Unauthorized request. Redirecting to login page.');
      alert('Unauthorized request. Redirecting to login page.');
      // You can perform additional actions here, such as redirecting to a login page.
    } else {
      // Handle other errors
      console.error('Error:', error.error);
      alert(error.error);
    }

    // Return an observable with a user-facing error message
    return throwError('Something went wrong. Please try again later.');
  }
  goBack() {
    this.router.navigateByUrl('/back', { skipLocationChange: true });
  }
  
}
