<div class="modal" tabindex="-1" role="dialog" (keydown.esc)="closePopup()" style="display:block;">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title d-flex align-items-center mt-4" style="height: 100%;">
          <span class="text-center">Select Client</span>
        </h3>
        <button type="button" class="btn-close" aria-label="Close" (click)="closePopup()">
          <!-- <i class="bi bi-x"></i> -->
        </button>
      </div>
      <div class="modal-body">
        <table class="table table-striped table-hover">
          <thead class="thead-dark">
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Name</th>
              <!-- <th scope="col">Address</th>
              <th scope="col">Edb</th>
              <th scope="col">Email</th> -->
              <!-- <th scope="col">Actions</th> -->
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of matClients" (click)="selectClient(item.id, item.name)" style="cursor:pointer">
              <td>{{ item.id }}</td>
              <td>{{ item.name }}</td>
            </tr>
          </tbody>
        </table>
        <div style="text-align: center;" *ngIf="isLoading">
          <span class="spinnerplace" >
            <i class="spinner"></i> Loading...
          </span>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closePopup()">Close</button>
      </div>
    </div>
  </div>
</div>
