export function getCurrentTimezoneOffsetString(): string {
    const timezoneOffsetMinutes = new Date().getTimezoneOffset();
    const offsetSign = timezoneOffsetMinutes >= 0 ? '-' : '+';
    const offsetHours = Math.abs(Math.floor(timezoneOffsetMinutes / 60));
    const offsetMinutes = Math.abs(timezoneOffsetMinutes % 60);
    
    return `${offsetSign}${padNumber(offsetHours)}:${padNumber(offsetMinutes)}:00`;
  }
  
  function padNumber(num: number): string {
    return num.toString().padStart(2, '0');
  }
  export function encodeURIComponent(uri: string): string {
    return encodeURIComponentHelper(uri);
  }
  
  function encodeURIComponentHelper(uri: string): string {
    const encodeURIComponentMap = {
      '!': '%21',
      "'": '%27',
      '(': '%28',
      ')': '%29',
      '~': '%7E',
      '%20': '+',
      '%00': '\x00'
    };
  
    return encodeURIComponentHelperRecursive(uri, encodeURIComponentMap);
  }
  
  function encodeURIComponentHelperRecursive(uri: string, map: { [key: string]: string }): string {
    let encodedUri = '';
  
    for (let i = 0; i < uri.length; i++) {
      const char = uri.charAt(i);
  
      if (map.hasOwnProperty(char)) {
        encodedUri += map[char];
      } else {
        encodedUri += char;
      }
    }
  
    return encodedUri;
  }
  
  export {}; // Add this line to explicitly mark the file as a module