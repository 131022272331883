<div class="container">
  <nav class="navbar navbar-expand-lg navbar-light bg-light shadow-sm rounded" *ngIf="isLogin()">
    <a class="navbar-brand" routerLink="/">
      <div class="d-flex justify-content-center align-items-center">
        <img src="https://vvircompany.com/assets/img/vlogo.svg" width="30" height="30" alt="Company Logo" class="d-inline-block align-top ms-3">
        <!-- <img src="https://benlind.eu/logo.png" alt="Company Logo" width="40" height="40" class="d-inline-block align-top ms-3"> -->
      </div>
    </a>

    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item" *ngIf="isAuthenticatedTwo('s290')" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
          <a class="nav-link" [routerLink]="['/mat-cart']" [queryParams]="{access: encodeURIComponentC(getEncFromFormId('s290'))}">
            <i class="bi bi-receipt"></i> Mat-Cart
          </a>
        </li>
        <li class="nav-item" *ngIf="isAuthenticatedTwo('s272')" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
          <a class="nav-link" [routerLink]="['/outdocs']" [queryParams]="{access: encodeURIComponentC(getEncFromFormId('s272'))}">
            <i class="bi bi-calendar-check"></i> Reservations
          </a>
        </li>
        <li class="nav-item" *ngIf="isAuthenticatedTwo('s263')" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
          <a class="nav-link" [routerLink]="['/outdocs']" [queryParams]="{access: encodeURIComponentC(getEncFromFormId('s263'))}">
            <i class="bi bi-truck"></i> Delivery Notes
          </a>
        </li>
        <li class="nav-item" *ngIf="isAuthenticatedTwo('s213')" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
          <a class="nav-link" [routerLink]="['/outdocs']" [queryParams]="{access: encodeURIComponentC(getEncFromFormId('s213'))}">
            <i class="bi bi-receipt"></i> Invoices
          </a>
        </li>
        <li class="nav-item" *ngIf="isAuthenticatedTwo('s501')" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
          <a class="nav-link" [routerLink]="['/order']" [queryParams]="{access: encodeURIComponentC(getEncFromFormId('s501'))}">
            <i class="bi bi-bag"></i> Order
          </a>
        </li>
        <li class="nav-item" *ngIf="isAuthenticatedTwo('s501')" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
          <a class="nav-link" [routerLink]="['/cart']" [queryParams]="{access: encodeURIComponentC(getEncFromFormId('s501'))}">
            <i class="bi bi-cart-fill"></i> Cart ({{ cartCount }})
          </a>
        </li>
      </ul>
      <ul class="navbar-nav">
        <li class="nav-item" *ngIf="!isAuthenticated()" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
          <a class="nav-link" routerLink="/login"><i class="bi bi-box-arrow-in-right"></i> Login</a>
        </li>
        <li class="nav-item" *ngIf="isAuthenticated()">
          <a class="nav-link" (click)="logout()"><i class="bi bi-box-arrow-left"></i> Logout</a>
        </li>
      </ul>
    </div>
  </nav>

  <router-outlet></router-outlet>
</div>
