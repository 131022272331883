import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { User } from './user.model';
import { Store } from './store.model';
import { Connection } from './connection.model';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-multistore-selector',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './multistore-selector.component.html',
  styleUrl: './multistore-selector.component.css',
})
export class MultiStoreSelectorComponent {
  viewdata : any;
  user: User | any;
  stores: Store[] = [];
  connections: Connection[] = [];
  selectedStoreId: number | null = null;
  selectedConnectionId: number | null = null;
  constructor(private http: HttpClient,private authService: AuthService,) {
    
   }
   
   ngOnInit(): void {
      let json = this.authService.getMultistoreResults();
      this.viewdata = JSON.parse(json);
      this.user = this.viewdata.user;
      this.stores = this.viewdata.stores;
      this.connections = this.viewdata.connections;

   }

  // Show connections for the selected store
  showConnections(storeId: number): void {
    this.selectedStoreId = storeId;
    this.connections = this.connections.filter(conn => conn.account_no === storeId);
  }
  // Method to generate the encoded URL
  getEncodedUrl(api_call: string,token: string, identity: string): string {
    const apiCall = encodeURIComponent(api_call);
    const encodedToken = encodeURIComponent(token);
    const encodedIdentity = encodeURIComponent(identity);
    return `/login?api_call=${apiCall}&account_token=${encodedToken}&account_identity=${encodedIdentity}`;
  }
}
