// mat-cart.component.ts
import { Injectable ,Component, OnInit, Input,ViewChild, ElementRef } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { MatCart1 } from './mat-cart1.model'; // Import the C# model
import { tmpMatCartParams } from './tmpMatCartParams.model';
import { API_ENDPOINTS } from '../api.config';
import { Observable, throwError  } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientPopupComponent } from '../client/client-popup.component';
import {NgbModule , NgbDate, NgbCalendar, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { getCurrentTimezoneOffsetString } from '../valon.config';
import { AuthService } from '../services/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  standalone: true,
  selector: 'app-mat-cart',
  templateUrl: './mat-cart.component.html',
  styleUrls: ['./mat-cart.component.css'],
  imports: [CommonModule,FormsModule,NgbModule],
})


export class MatCartComponent implements OnInit {
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  dp1: any; // Add dp as a property
  dp2: any; // Add dp as a property
  client_id:number=0;
  isPopupOpen:boolean=false;
  @ViewChild('clientIdInput') clientIdInput!: ElementRef;
  @ViewChild('fromDateInput') fromDateInput!: ElementRef;
  @ViewChild('toDateInput') toDateInput!: ElementRef;
  @ViewChild('languageInput') languageInput!: ElementRef;
  @ViewChild('offsetInput') offsetInput!: ElementRef;
  matCartList: MatCart1[] = [];
  access:any;
  isLoading = false;
  constructor(private route: ActivatedRoute,private authService: AuthService,private http: HttpClient, private modalService: NgbModal,private calendar: NgbCalendar, public formatter: NgbDateParserFormatter) {
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getToday();
   }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      const encodedAccess = params.get('access');
      this.access = encodedAccess ? decodeURIComponent(encodedAccess) : null;
    });
  }
  onFromDateSelect(date: any): void {
    // Handle the selected date, you can convert it to a string or use it as needed
    this.fromDate = date;
    const formattedDate = this.formatter.format(date);
    console.log(formattedDate);

    // Update your HTTP request or any logic based on the selected date
    //this.fetchMatCartList(formattedDate);
  }
  onToDateSelect(date: any): void {
    // Handle the selected date, you can convert it to a string or use it as needed
    this.toDate = date;
    const formattedDate = this.formatter.format(date);
    console.log(formattedDate);

    // Update your HTTP request or any logic based on the selected date
    //this.fetchMatCartList(formattedDate);
  }
  openClientPopup() {
    if(this.isPopupOpen==true)return;
    this.isPopupOpen=true;
    const options = { windowClass: 'custom-ngb-modal-window', backdropClass: 'custom-ngb-modal-backdrop',width: '100vw',
    maxWidth: '200vw' };
    const modalRef = this.modalService.open(ClientPopupComponent,options);
    
    // Subscribe to the client selection event in the popup
    modalRef.componentInstance.clientSelected.subscribe((selectedClient: { id: number, name: string }) => {
      
      this.clientIdInput.nativeElement.value = selectedClient.name;
      this.client_id=selectedClient.id;
      this.isPopupOpen=false;
     
    });
  }
  openClientLocationsPopup() {
    const options = { windowClass: 'custom-ngb-modal-window', backdropClass: 'custom-ngb-modal-backdrop',width: '100vw',
    maxWidth: '200vw' };
    const modalRef = this.modalService.open(ClientPopupComponent,options);

    // Subscribe to the client selection event in the popup
    modalRef.componentInstance.clientSelected.subscribe((selectedClient: { id: number, name: string }) => {
      this.clientIdInput.nativeElement.value = selectedClient.name;
      this.client_id=selectedClient.id;
    });
  }
  search(): void {
    
    const clientId = this.client_id;
    const formattedFromDate = this.fromDate ? this.formatter.format(this.fromDate) : '';
    const formattedToDate = this.fromDate ? this.formatter.format(this.toDate) : '';
    const language = "en";
    const newOffset = getCurrentTimezoneOffsetString();

    // Validate clientId
    if (clientId === 0) {
      //console.error('Client ID is null or undefined. Please select a client.');
      alert('Client ID is null or undefined. Please select a client.');
      return;
    }
    this.isLoading=true;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.getAuthToken(),  // Add your authorization header
      'Content-Type': 'application/json'  // Adjust content type based on your API requirements
    });

    /*const params: tmpMatCartParams = {
      client_id: 1,
      fromDate: '2022-01-01',
      toDate: '2022-02-01',
      language: 'en',
      offset: '0'
    };*/
  
    const body = {
      access:this.access,
      client_id:clientId,
      fromDate:formattedFromDate,
      toDate:formattedToDate,
      language,
      offset:newOffset
    };
    this.http.post<MatCart1[]>(this.authService.getAccountAPI() +  API_ENDPOINTS.vvclient_method + "/GetMatCartList", body,{headers })
    .pipe(
      catchError(this.handleError)
    )
    .subscribe(
      data => {
        //alert(JSON.stringify(data));
        this.matCartList = data.result;
        this.isLoading=false;
      },
      error => {
        console.error('Error fetching MatCartList:', error);
        this.isLoading=false;
        alert(error);
      }
    );
  }
  private handleError(error: HttpErrorResponse): Observable<any> {
    if (error.status === 401) {
      // Handle 401 Unauthorized error (e.g., redirect to login page)
      console.error('Unauthorized request. Redirecting to login page.');
      alert('Unauthorized request. Redirecting to login page.');
      // You can perform additional actions here, such as redirecting to a login page.
    } else {
      // Handle other errors
      console.error('Error:', error.error);
      alert(error.error);
    }

    // Return an observable with a user-facing error message
    return throwError('Something went wrong. Please try again later.');
  }

}
