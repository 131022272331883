import { Injectable } from '@angular/core';
import { Product } from '../order/models/product.model';
import { AuthService } from './auth.service';
import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { MatItems } from '../items/items-popup.model';
import { API_ENDPOINTS } from '../api.config';
import { Observable, throwError  } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AccessService } from './access.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  matItems: MatItems[] = [];
  constructor(private authService: AuthService,private http: HttpClient,private accessServoce : AccessService) { }

  getProducts(): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.getAuthToken(),  // Add your authorization header
      'Content-Type': 'application/json'
    });
    let search =  "";
    let access = this.accessServoce.filterDataByFormId("s203");
    const body = {
      search,
      access:access[0].enc
    };
    return this.http.post<any>(this.authService.getAccountAPI() +  API_ENDPOINTS.vvclient_method + "/GetItems", body,{headers });
    
  }
  private handleError(error: HttpErrorResponse): Observable<any> {
    if (error.status === 401) {
      // Handle 401 Unauthorized error (e.g., redirect to login page)
      console.error('Unauthorized request. Redirecting to login page.');
      alert("Unauthorized request. Redirecting to login page.");
      // You can perform additional actions here, such as redirecting to a login page.
    } else {
      // Handle other errors
      console.error('Error:', error.error);
      alert(error.error);
    }

    // Return an observable with a user-facing error message
    return throwError('Something went wrong. Please try again later.');
  }
}
