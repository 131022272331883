// auth.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  setAuthToken(token: string | null): void {
    localStorage.setItem('authToken', token || '');
  }

  getAuthToken(): string | null {
    return localStorage.getItem('authToken');
  }

  isAuthenticated(): boolean {
    return !!this.getAuthToken();
  }

  clearAuthToken(): void {
    localStorage.removeItem('authToken');
  }


  setMultistoreAuthToken(token: string | null): void {
    localStorage.setItem('authMultistoreToken', token || '');
  }

  getMultistoreAuthToken(): string | null {
    return localStorage.getItem('authMultistoreToken');
  }

  isMultistoreAuthenticated(): boolean {
    return !!this.getMultistoreAuthToken();
  }

  clearMultistoreAuthToken(): void {
    localStorage.removeItem('authMultistoreToken');
  }

  setMultistoreResults(item: string | null): void {
    localStorage.setItem('multistoreResults', item || '');
  }
  getMultistoreResults(): string {
    return localStorage.getItem('multistoreResults') || '';
  }
  setAccountToken(item: string | null): void {
    localStorage.setItem('accountToken', item || '');
  }
  getAccountToken(): string {
    return localStorage.getItem('accountToken') || '';
  }
  setAccountIdentity(item: string | null): void {
    localStorage.setItem('accountIdentity', item || '');
  }
  getAccountIdentity(): string {
    return localStorage.getItem('accountIdentity') || '';
  }
  setAccountAPI(item: string | null): void {
    localStorage.setItem('accountAPI', item || '');
  }
  getAccountAPI(): string {
    return localStorage.getItem('accountAPI') || '';
  }
}
